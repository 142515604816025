










































































import { Bind, Debounce } from 'lodash-decorators'
import { DateTime } from 'luxon'
import { MetaInfo } from 'vue-meta'
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'

// components
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import CureHeartModal from '@/components/modals/exercise/CureHeartModal.vue'
import Hearts from '@/components/Hearts.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import TakeHeartModal from '@/components/modals/exercise/TakeHeartModal.vue'
import UserCard from '@/components/cards/UserCard.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CourseType,
  EducationLargeTaskResource,
  ExerciseMasterResource,
  ExerciseShortResource,
  ExerciseStatus, MasterLargeResource, MasterWhoCompleteTaskResource, TaskType,
} from '@/store/types'
import { ICureAndTakeHeartForm } from '@/store/types/forms'
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    ButtonIconAction,
    ButtonTextIcon,
    CureHeartModal,
    Hearts,
    LinkTabs,
    TakeHeartModal,
    UserCard,
  },
})
export default class ExercisesItemTaskMaster extends Mixins(NotifyMixin) {

  @Prop({ required: true })
  private groupId!: number

  @Prop({ required: true })
  private exercise!: ExerciseShortResource

  @Prop({ required: true })
  private taskUuid!: string

  @Prop({ default: [] })
  private masters!: ExerciseMasterResource[]

  private show = false

  private showModalCureHeart = false
  private showModalTakeHeart = false

  private isLoading = true

  private currentMasterIndex = -1

  private isLostHeart = Boolean(this.task?.lostHeart)
  private hearts = 0

  private get masterID () {
    return +this.$route.params.masterID
  }

  private master: MasterLargeResource | null = null

  private get isStatusWait() {
    return this.task?.status.value === ExerciseStatus.WAIT
  }

  private get isStatusInProcess() {
    return this.task?.status.value === ExerciseStatus.IN_PROCESS
  }

  private get neighbourMasters() {
    return MentorExercisesModule.neighbourMasters.list
  }

  private get currentMaster() {
    return MentorExercisesModule.neighbourMasters.list?.find((item: MasterWhoCompleteTaskResource, index) => {
      if (item.id === this.masterID) {
        this.currentMasterIndex = index
      }
      return item.id === this.masterID
    })
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private formatMasterDate(date: string) {
    return formatDate(date, 'dd MMM, HH:mm', this.isLocalTimezone)
  }

  private changeMasterAnswer(id: string) {
    this.isLoading = true
    this.$router.replace({
      name: 'manager.education.exercises.item.task.master.answer',
      params: {
        exerciseUUID: this.$route.params.exerciseUUID,
        groupID: this.$route.params.groupID,
        masterID: id,
        taskUUID: this.$route.params.taskUUID,
      },
    })
    this.isLoading = false
  }

  private get masterOnLeft() {
    let masterOnLeftIndex = null
    if (MentorExercisesModule.neighbourMasters.list?.length && MentorExercisesModule.neighbourMasters.list?.length > 1) {
      if (this.currentMasterIndex - 1 < 0) {
        masterOnLeftIndex = MentorExercisesModule.neighbourMasters.list?.length - 1
      } else {
        masterOnLeftIndex = this.currentMasterIndex - 1
      }
      if (MentorExercisesModule.neighbourMasters.list[masterOnLeftIndex].id === this.masterOnRight?.id) {
        return null
      } else {
        return MentorExercisesModule.neighbourMasters.list[masterOnLeftIndex]
      }
    } else {
      return null
    }
  }

  private get masterOnRight() {
    let masterOnRightIndex = null
    if (MentorExercisesModule.neighbourMasters.list?.length && MentorExercisesModule.neighbourMasters.list?.length > 1) {
      if (this.currentMasterIndex + 1 > MentorExercisesModule.neighbourMasters.list.length - 1) {
        masterOnRightIndex = 0
      } else {
        masterOnRightIndex = this.currentMasterIndex + 1
      }
      return MentorExercisesModule.neighbourMasters.list[masterOnRightIndex]
    } else {
      return null
    }
  }

  private get isCurrentMonth() {
    const dt = DateTime.fromMillis(Date.now()).toISODate().substring(0, 7)
    return dt === this.exercise.spendingAt?.substring(0, 7) || dt === this.exercise.deadlineAt.substring(0, 7)
  }

  // Текущий ли месяц, относительно даты снятия жизни
  private get isCurrentMonthLostHeart() {
    if (this.task && this.task.lostHeart) {
      return formatDate(DateTime.now().setZone('Europe/Moscow').toSQLDate(), 'MM-yyyy') === formatDate(this.task.lostHeart, 'MM-yyyy')
    }
    return false
  }

  private get isSpecial(): boolean {
    return Boolean(this.exercise.course.type.value === CourseType.SPECIAL)
  }

  private get taskTabs() {
    return this.exercise.tasks.map(task => ({
      exact: false,
      name: task.type.name,
      to: {
        name: this.$route.name,
        params: {
          exerciseUUID: this.exercise.uuid,
          groupID: this.groupId,
          masterID: this.masterID,
          taskUUID: task.uuid,
        },
      },
    }))
  }

  private get tabs () {
    return [{
      exact: false,
      name: 'Домашняя работа',
      to: {
        name: 'manager.education.exercises.item.task.master.answer',
        params: {
          exerciseUUID: this.exercise.uuid,
          groupID: this.groupId,
          masterID: this.masterID,
          taskUUID: this.exercise.tasks.length > 1 ? this.$route.params.taskUUID : this.taskUuid,
        },
      },
    }, {
      exact: false,
      name: 'Информация',
      to: {
        name: 'manager.education.exercises.item.task.master.information',
        params: {
          exerciseUUID: this.exercise.uuid,
          groupID: this.groupId.toString(),
          masterID: this.masterID.toString(),
          taskUUID: this.exercise.tasks.length > 1 ? this.$route.params.taskUUID : this.taskUuid,
        },
      },
    }, {
      exact: false,
      name: 'Сообщения',
      to: {
        name: 'manager.education.exercises.item.task.master.messages',
        params: {
          exerciseUUID: this.exercise.uuid,
          groupID: this.groupId,
          masterID: this.masterID,
          taskUUID: this.exercise.tasks.length > 1 ? this.$route.params.taskUUID : this.taskUuid,
        },
      },
    }]
  }

  private get isMasterRootRoute () {
    return this.$route.name === 'manager.education.exercises.item.task.master'
  }

  private get user() {
    if (this.master) {
      return this.master.user
    }
    return null
  }

  private get isPractice() {
    return Boolean(this.task?.type.value === TaskType.PRACTICE)
  }

  private get task() {
    return MentorExercisesModule.task
  }

  private get title() {
    return this.master && this.task ? `${this.master.user.name} ${this.master.user.surname} - ${this.task.title} - Проверка домашнего задания` : 'Проверка домашнего задания'
  }

  private mounted () {
    this.fetchMaster()
    this.fetchMasterTask()
    this.fetchNeighbourMasters()

    this.$bus.$on('rate-master-task', this.fetchMasterTask)
    this.$bus.$on('close-master-task', this.fetchMasterTask)
  }

  private destroyed() {
    this.$bus.$off('rate-master-task', this.fetchMasterTask as any)
    this.$bus.$off('close-master-task', this.fetchMasterTask as any)
    MentorExercisesModule.unsetMessages()
  }

  private fetchMaster() {
    MentorExercisesModule.fetchMaster({
      masterGroupID: this.groupId,
      masterUUID: this.masterID,
      monthID: this.exercise.monthId,
    })
      .then((response: MasterLargeResource) => {
        this.master = response
        this.hearts = this.master.hearts
      })
      .catch(this.notifyError)
  }

  private fetchNeighbourMasters() {
    MentorExercisesModule.fetchNeighbourMasters({
      masterGroupID: this.groupId,
      taskUUID: this.taskUuid,
    })
  }

  @Bind
  @Debounce(300)
  private fetchMasterTask (withRedirect = true) {
    this.isLoading = true
    MentorExercisesModule.fetchMasterTask({
      masterGroupID: this.groupId,
      masterID: this.masterID,
      taskUUID: this.taskUuid,
    })
      .then((response: EducationLargeTaskResource) => {
        if (response.type.value === TaskType.TEST && response.status.value !== ExerciseStatus.WAIT && response.status.value !== ExerciseStatus.IN_PROCESS) {
          MentorExercisesModule.fetchMistakes({
            masterGroupID: this.groupId,
            masterID: this.masterID,
            taskUUID: this.taskUuid,
          })
            .catch(this.notifyError)
        }

        if (this.isMasterRootRoute && withRedirect) {
          this.$router.replace({
            name: 'manager.education.exercises.item.task.master.answer',
            params: {
              exerciseUUID: this.exercise.uuid,
              groupID: this.groupId.toString(),
              masterID: this.masterID.toString(),
              taskUUID: this.taskUuid,
            },
          })
        }
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({
          name: this.isSpecial ? 'manager.education.exercises.quickStart' : 'manager.education.exercises.item.masterList',
          params: {
            exerciseUUID: this.exercise.uuid,
            groupID: this.groupId.toString(),
            taskUUID: this.taskUuid,
          },
        })
      })
      .finally(() => this.isLoading = false)
  }

  private handleCure(form: ICureAndTakeHeartForm) {
    if (this.master) {
      ManagerGroupsModule.cureGroupMaster({
        body: {
          message: form.message,
        },
        exerciseID: form.exerciseID || this.exercise.id,
        masterGroupID: +this.groupId,
        masterID: this.master.user.id,
      })
        .then(() => {
          this.notifySuccess(`Вы вернули жизнь мастеру ${this.master?.user.name} ${this.master?.user.surname}`)
          this.isLostHeart = false
          this.hearts++
        })
        .catch(this.notifyError)
    }
  }

  private handleTake(form: ICureAndTakeHeartForm) {
    if (this.master) {
      ManagerGroupsModule.takeGroupMaster({
        body: {
          message: form.message,
        },
        exerciseID: form.exerciseID || this.exercise.id,
        masterGroupID: +this.groupId,
        masterID: this.master.user.id,
      })
        .then(() => {
          this.notifySuccess(`Вы сняли жизнь мастеру ${this.master?.user.name} ${this.master?.user.surname}`)
          this.isLostHeart = true
          this.hearts--
        })
        .catch(this.notifyError)
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.title,
    }
  }

  @Watch('task')
  private watchTask() {
    this.isLostHeart = Boolean(this.task?.lostHeart)
  }
}
